//-------------------------
//-- Grids
//-------------------------
$grid-breakpoints: ();
$grid-breakpoints: (
  xxs: 0px,
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);


$responsive-row:(
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false
)


