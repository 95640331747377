//-------------------------
//--- Theme Typography
//-------------------------
$enable-responsive-font-sizes: true;
$font-family-base:            'Circular Std', sans-serif;

//custom font-family
$btn-font-family: $font-family-base;
$heading-font-family: $font-family-base;
$paragraph-font-family: $font-family-base;


$multiple-font-family: false;
$font-families: (
  "1": $font-family-base,
  "2": $font-family-base,
  "fontName": $font-family-base
);


$headings-font-weight:        700;
// $headings-line-height:        1.5;

//- Display Sizes

$display1-size:               6rem; // About 96px
$display2-size:               5rem; // About 80px
$display3-size:               3.75rem; // About 60px
$display4-size:               3.125rem; // About 50px


//- Font Sizes 
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                13px;

$h1-font-size:                3.625rem; //About 58px
$h2-font-size:                3rem; //About 48px
$h3-font-size:                2.125rem;  //About 34px
$h4-font-size:                1.5rem; //About 24px
$h5-font-size:                1.3125rem; //About 21px
$h6-font-size:                1rem; //About 16px



$text-base:                  1.125rem; //18px


$fsize: (
  1:(
    font-size:8px,  //8px
    line-height:1,
  ),
  2:(
    font-size:11px, //11px
    line-height:1.1
    ),
  3:(
    font-size:13px, //13px
    line-height: 1.53846
  ), //-0.4
  4:(
    font-size:14px, //14px
    line-height:1,
  ), // 
  5:(
    font-size: 16px,//16px
    line-height: 1.75
    ),
  6:(
    font-size: 18px,  //18px
    line-height: 1.77777
  ),
  7:(
    font-size: 21px, //21px
    line-height: 1.61904
    ),
  8:(
    font-size: 24px, //24px
    line-height: 1.416666
    ),
  9:(
    font-size:34px, //34px
    line-height: 1.32352,
    letter-spacing: -0.59px
    ),
  10:(
    font-size:48px, //48px
    line-height: 1.16666,
    letter-spacing:-0.03px
    ),
  11:(
    font-size:58px,//58px
    line-height:1.10344,
    letter-spacing:-1px
    ),
  12:(
    font-size: 70px,//70px
    line-height:1,
    letter-spacing: -1px
    ),
  13:(
    font-size: 80px,//80px
    line-height:0.8,
    letter-spacing: -1.38px
    )
);